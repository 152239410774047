import { useEffect } from "react";
import "./App.css";

function App() {
  useEffect(() => {
    document.title = "eubyt.dev | Hello 👋";
  }, []);

  return (
    <div className="App">
      <header className="header">
        <p>Eubyt ❤️ 9Head</p>
        <a
          className="link"
          href="https://discord.gg/TwUrTq2bnn"
          target="_blank"
          rel="noopener noreferrer"
        >
          Discord Server
        </a>
      </header>
    </div>
  );
}

export default App;
